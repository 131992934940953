export function TwilioLogo() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="40pt"
      height="40pt"
      viewBox="0 0 225.000000 225.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>Created by potrace 1.10, written by Peter Selinger 2001-2011</metadata>
      <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
        <path
          d="M1045 2179 c-41 -39 -75 -77 -75 -83 0 -7 71 -82 158 -169 l158 -156
-323 -323 -323 -323 325 -325 326 -326 -161 -158 c-88 -87 -160 -162 -160
-166 0 -3 34 -39 75 -79 61 -60 77 -70 92 -62 10 5 265 257 567 560 l550 550
-19 23 c-36 44 -1060 1069 -1087 1089 l-27 20 -76 -72z m1152 -1069 c-6 -8
-249 -253 -538 -544 -400 -401 -531 -526 -542 -520 -8 5 -35 32 -60 59 l-45
51 142 145 c79 80 145 156 149 169 6 22 -30 60 -305 337 -283 284 -310 314
-298 330 8 10 145 149 304 310 159 160 293 300 297 311 10 26 -8 48 -170 211
l-122 124 58 59 58 58 542 -542 c457 -457 540 -544 530 -558z"
        />
        <path
          d="M1425 1630 c-51 -20 -165 -143 -165 -178 0 -8 68 -83 150 -167 83
-84 150 -156 150 -161 0 -15 -94 -104 -109 -104 -8 0 -81 65 -162 145 -114
112 -154 145 -174 145 -38 0 -175 -138 -175 -177 0 -23 42 -69 243 -270 175
-175 250 -243 266 -243 16 0 83 60 233 208 248 245 272 273 264 307 -6 22
-452 477 -485 494 -9 5 -25 5 -36 1z m265 -262 l245 -248 -243 -243 -242 -242
-248 248 -248 248 80 83 81 82 167 -165 168 -166 75 75 c41 41 75 79 75 85 0
5 -72 82 -161 171 l-160 160 78 81 c43 44 80 80 83 80 3 0 115 -112 250 -249z"
        />
        <path
          d="M399 1534 c-348 -349 -400 -404 -391 -420 6 -11 187 -194 401 -409
l391 -390 82 83 83 82 -323 322 -322 323 322 322 323 323 -83 82 -82 83 -401
-401z m462 297 c27 -26 49 -53 49 -59 0 -6 -135 -145 -300 -310 -178 -179
-302 -310 -306 -325 -8 -36 4 -51 326 -371 154 -154 280 -285 280 -291 0 -16
-96 -108 -109 -104 -14 5 -751 738 -754 750 -2 11 744 759 757 759 5 0 31 -22
57 -49z"
        />
      </g>
    </svg>
  );
}
